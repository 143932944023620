import 'react-phone-input-2/lib/style.css';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Hidden,
  Input,
  Link,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Field, useField } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Grid from '@material-ui/core/Grid';
import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import Wizard from './Wizard.js';
import { fieldToTextField } from 'formik-material-ui';
import joinUs from '../../static/img/login.jpg';
import logo from '../../static/img/new-ae-logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../context/auth';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    display: 'block',
  },
  multiSelect: {
    marginBottom: 15,
    '& div': {
      background: 'none!important',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#777777',
    padding: 0,
    fontWeight: 'normal',
    marginTop: 20,
  },
  mobileFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
    },
    backgroundImage: 'url(' + joinUs + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  reactTelInput: {
    '& input': {
      width: '100%!important',
    },
  },
  joinGrid: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 50,
      marginBottom: 50,
    },
  },
  logo: {
    height: 40,
    margin: '5px 10px',
  },
  joinText: {
    fontSize: 35,
    fontWeight: 'bold',
    lineHeight: 1.35,
    color: '#172b4d',
  },
  welcomeText: {
    color: '#777777',
    [theme.breakpoints.up('xl')]: {
      marginTop: 40,
    },
    marginTop: 10,
  },
  inputText: {
    marginTop: 15,
    fontWeight: 600,
  },
}));

export default function InvestorJoinUs(props) {
  document.body.style = 'background: #ffffff;margin:0';
  const TEST_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  const { setAuthToken } = useAuth();

  const FormikTextField = ({ label, ...props }) => {
    const [showPassword] = React.useState(false);
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={props.type === 'password' ? (showPassword ? 'text' : 'password') : 'text'}
          />
          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  };

  const FormikCheckBox = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <Grid container alignItems="center">
        <Hidden mdDown>
          <Grid item>
            <FormControlLabel
              style={{ marginRight: 0 }}
              control={
                <Checkbox
                  {...field}
                  {...props}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  color="primary"
                />
              }
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={1}>
            <FormControlLabel
              style={{ marginRight: 0 }}
              control={
                <Checkbox
                  {...field}
                  {...props}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  color="primary"
                />
              }
            />
          </Grid>
        </Hidden>
        <Grid item xs={11}>
          {label}
        </Grid>
        {meta.touched && meta.error ? (
          <Typography className={classes.label} style={{ color: '#FF0000' }} variant="subtitle2">
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    );
  };
  const FormikNewDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const {
      form: { setTouched, setFieldValue },
    } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
      <>
        <Autocomplete
          style={{ marginTop: 10 }}
          {...props}
          {...field}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={() => setTouched({ [name]: true })}
          renderInput={(props) => <TextField {...props} {...textFieldProps} variant="outlined" />}
        ></Autocomplete>

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  };
  const FormikMultiSelect = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const {
      form: { setTouched, setFieldValue },
    } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
      <>
        <Autocomplete
          multiple
          style={{ marginTop: 10 }}
          {...props}
          {...field}
          disableCloseOnSelect
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={() => setTouched({ [name]: true })}
          renderInput={(props) => <TextField {...props} {...textFieldProps} variant="outlined" />}
        ></Autocomplete>

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  };
  const FormikRecapthca = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <ReCAPTCHA style={{ display: 'inline-block' }} sitekey={TEST_SITE_KEY} {...field} {...props} />

        {meta.touched && meta.error ? (
          <Typography className={classes.label} style={{ color: '#FF0000' }} variant="subtitle2">
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  };
  const options = ['Social Media', 'Friends and Family', 'Investing Platforms', 'Angel Effect Shareholders'];
  const totalInvestedCompanies = ['Do not want to indicate', '0', '1-3', '3-5', '5-10', '10+'];
  const totalInvestmenAmount = ['0-$100K', '$100K - $250K', '$250K - $500K', '$500K+'];
  const budgetForAE = ['0-$100K', '$100K - $250K', '$250K - $500K', '$500K+'];
  const planInvestTwoYear = ['0', '1-3', '3-5', '5-10', '10+'];
  const industry = [
    'Agriculture',
    'Biotechnology',
    'Business Services',
    'Clean Technology',
    'Construction',
    'Consulting',
    'Consumer Products',
    'Consumer Services',
    'Digital Marketing',
    'Education',
    'Electronics / Instrumentation',
    'Fashion',
    'Financial Services',
    'Fintech',
    'Food and Beverage',
    'Gaming',
    'Healthcare Services',
    'Energy',
    'Internet / Web Services',
    'IT Services',
    'Legal',
    'Lifestyle',
    'Marine',
    'Maritime/Shipping',
    'Marketing / Advertising',
    'Media and Entertainment',
    'Medical Devices and Equipment',
    'Mobility',
    'Nanotechnology',
    'Other',
    'Real Estate',
    'Retailing / Distribution',
    'Robotics',
    'Security',
    'Software',
    'Sports',
    'Telecommunications',
    'Transportation',
    'Travel',
  ];
  const preventDefault = (event) => event.preventDefault();
  const classes = useStyles();
  function validateLinkedinUrl(value) {
    let error;
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    error = !pattern.test(value) ? 'Not valid URL.' : false;
    return error;
  }
  return (
    <Grid container style={{ height: '100vh' }}>
      <Hidden mdDown>
        <Grid item sm={6} xs={12}>
          <Grid className={classes.leftGrid}></Grid>
        </Grid>
      </Hidden>

      <Grid item lg={6} md={12} xs={12} container style={{ maxHeight: '100%', overflowY: 'auto' }}>
        <Grid sm={12} xl={12} xs={12} alignItems="center" justify="center" className={classes.joinGrid} container item>
          <Grid item xl={6} sm={8} xs={11} style={{ maxHeight: 'fit-content', marginTop: 50 }}>
            <Grid container item>
              <Typography className={classes.joinText}>Join</Typography>
              <img src={logo} alt="angel-effect" className={classes.logo}></img>
              <Typography className={classes.joinText}>Family</Typography>
            </Grid>
            <Typography className={classes.welcomeText}>
              Welcome! Please fill in the blanks for us to get to know you better and to contact you as soon as
              possible.
            </Typography>
            <Wizard
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                password: '',
                passwordConfirm: '',
                socialLinkedIn: '',
                hearAboutUs: '',
                totalInvestedCompanies: '',
                totalInvestmenAmount: '',
                budgetForAE: '',
                planInvestTwoYear: '',
                industry: [],
                userAgreement: false,
                reCaptcha: false,
              }}
              registerInvestor={props.registerInvestor}
              setAuthToken={setAuthToken}
              onSubmit={async (values) => {}}
            >
              <Wizard.Page>
                <Grid container xs={12} item spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.inputText}>
                      Name<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                    </Typography>
                    <FormikTextField name="firstName" placeholder="First Name"></FormikTextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.inputText}>
                      Surname<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                    </Typography>
                    <FormikTextField name="lastName" placeholder="Last Name"></FormikTextField>
                  </Grid>
                </Grid>
                <Grid container xs={12} item spacing={3}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      Email Address <span style={{ color: '#28b1e7' }}>*</span>
                    </Typography>
                    <FormikTextField name="email" placeholder="Email Address"></FormikTextField>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>Phone Number </Typography>
                    <Field name="phoneNumber">
                      {({ field, form, meta }) => {
                        const { name, value } = field;
                        return (
                          <>
                            <div>
                              <Grid style={{ minHeight: 95 }}>
                                <ReactPhoneInput
                                  name={name}
                                  value={value}
                                  style={{ marginTop: 10, borderRadius: 7 }}
                                  className={classes.inputField}
                                  onChange={(e) => form.setFieldValue('phoneNumber', e)}
                                  country={'tr'}
                                />
                                {meta.touched && meta.error ? (
                                  <Typography
                                    style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}
                                  >
                                    {meta.error}
                                  </Typography>
                                ) : null}
                              </Grid>
                            </div>
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} item spacing={3}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      Password<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                    </Typography>
                    <FormikTextField name="password" type="password" placeholder="Confirm Password"></FormikTextField>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      Confirm Password<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                    </Typography>
                    <FormikTextField name="passwordConfirm" type="password" placeholder="Password"></FormikTextField>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      LinkedIn<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                    </Typography>
                    <FormikTextField
                      name="socialLinkedIn"
                      placeholder="LinkedIn"
                      validate={validateLinkedinUrl}
                    ></FormikTextField>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      How did you hear about us?<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="hearAboutUs"
                      options={options}
                    ></Field>
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      In total, how many startup companies have you invested in?{' '}
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="totalInvestedCompanies"
                      options={totalInvestedCompanies}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>How much did you invest in total?</Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="totalInvestmenAmount"
                      options={totalInvestmenAmount}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      How much of your budget did you allocate for angel investment?
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="budgetForAE"
                      options={budgetForAE}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>
                      How many startup companies do you plan to invest in the next 2 years?
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="planInvestTwoYear"
                      options={planInvestTwoYear}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography className={classes.inputText}>What industries would you like to invest in?</Typography>
                    <Field
                      component={FormikMultiSelect}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="industry"
                      options={industry}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={3} style={{ marginTop: 30 }}>
                  <Grid item container xs={12} style={{ paddingTop: 0 }}>
                    <FormikCheckBox
                      name="userAgreement"
                      type="Checkbox"
                      label={
                        <Typography className={classes.mobileFont} style={{ display: 'flex', alignItems: 'center' }}>
                          I agree to
                          <Link
                            href="https://angeleffect.co/privacy-and-cookie-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.mobileFont}
                            style={{
                              fontWeight: 'normal',
                              color: '#172b4d',
                              padding: 0,
                              textDecoration: 'underline',
                              marginLeft: 5,
                            }}
                          >
                            {'Privacy and Cookie Policy'}
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Wizard.Page>
            </Wizard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
