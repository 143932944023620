import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),

  password: yup.string().required('Required'),
});
export const addInvestorSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup.string().required('Required'),
});
export const firstLoginSchema = yup.object().shape({
  address: yup.string().required('Required'),
  userAgreement: yup.boolean().oneOf([true], 'You must accept the user agreement'),
});
export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().required('Required'),
});
export const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@.$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
    ),
  passwordAgain: yup
    .string()
    .required('Password Confirm is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
export const eventSchema = yup.object().shape({
  name: yup.string().nullable().required('Required'),
  startDate: yup.string().nullable().required('Required'),
  endDate: yup.string().nullable().required('Required'),
  link: yup.string().nullable().required('Required'),
  startupIds: yup.array().nullable().required('Required'),
});
export const signupSchema = [
  yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@.$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
      ),
    hearAboutUs: yup.string().nullable().required('Required'),
    passwordConfirm: yup
      .string()
      .required('Password Confirm is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  yup.object().shape({
    userAgreement: yup.boolean().oneOf([true], 'You must accept the user agreement'),
  }),
];
export const applicationSchema = [
  yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    jobTitle: yup.string().required('Required'),
    email: yup.string().email('Invalid email').required('Required'),
    linkedin: yup.string().required('Required'),
  }),
  yup.object().shape({
    founderMembers: yup.array().of(
      yup.object().shape({
        jobTitle: yup.string().nullable().required('Required'),
        nameSurname: yup.string().nullable().required('Required'),
        socialLinkedIn: yup.string().nullable().required('Required'),
      }),
    ),
    otherMembers: yup.array().of(
      yup.object().shape({
        jobTitle: yup.string().nullable().required('Required'),
        nameSurname: yup.string().nullable().required('Required'),
        socialLinkedIn: yup.string().nullable().required('Required'),
      }),
    ),
  }),
  yup.object().shape({
    title: yup.string().nullable().required('Required'),
    website: yup.string().nullable().required('Required'),
    industry: yup.string().nullable().required('Required'),
    stage: yup.string().nullable().required('Required'),
    description: yup.string().nullable().required('Required'),
    pitchDeck: yup.mixed().required(),
    businessPlan: yup.mixed().required(),
    havePayingCustomer: yup.string().nullable().required('Required'),
    productProblem: yup.string().nullable().required('Required'),
    productSolution: yup.string().nullable().required('Required'),
    totalRevenueGrowth: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    averageRevenueGrowth: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    revenueRecurring: yup.string().nullable().required('Required'),
    revenueRecurringType: yup
      .string()
      .nullable()
      .when('revenueRecurring', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
  }),
  yup.object().shape({
    firstCompetitorsName: yup.string().nullable().required('Required'),
    firstCompetitorsWebsite: yup.string().nullable().required('Required'),
    productDifFromComp: yup.string().nullable().required('Required'),
    marketTargetRegion: yup.string().nullable().required('Required'),
    marketTotalSize: yup.string().nullable().required('Required'),
  }),
  yup.object().shape({
    investmentNeededDD: yup.string().nullable().required('Required'),
    postMoneyValuation: yup.string().nullable().required('Required'),
    roundPercent: yup.string().nullable().required('Required'),
  }),
  yup.object().shape({}),
];
export const deepDiveSchema = [
  null,
  yup.object().shape({
    title: yup.string().nullable().required('Required'),
    contactEmail: yup.string().nullable().required('Required'),
    website: yup.string().nullable().required('Required'),
    socialPrimary: yup.string().nullable().required('Required'),
    city: yup.string().nullable().required('Required'),
  }),
  yup.object().shape({
    founderMembers: yup.array().of(
      yup.object().shape({
        jobTitle: yup.string().nullable().required('Required'),
        nameSurname: yup.string().nullable().required('Required'),
        socialLinkedIn: yup.string().nullable().required('Required'),
      }),
    ),
  }),
  yup.object().shape({
    kpi: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().required('Required'),
        status: yup.string().nullable().required('Required'),
      }),
    ),
    productProblem: yup.string().nullable().required('Required'),
    productSolution: yup.string().nullable().required('Required'),
    productDifFromComp: yup.string().nullable().required('Required'),
    productAdvantages: yup.string().nullable().required('Required'),
    havePayingCustomer: yup.string().nullable().required('Required'),
    payingCustomerAmount: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    averageUserGrowth: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    channelsGainedCustomer: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    revenueExplain: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    averageRevenueGrowth: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    revenueGross: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    revenueNet: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    netProfit: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    revenueRecurring: yup
      .string()
      .nullable()
      .when('havePayingCustomer', {
        is: 'Yes',
        then: yup.string().nullable().required('required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
  }),
  yup.object().shape({
    businessModel: yup.string().nullable().required('Required'),
    partnerships: yup.string().nullable().required('Required'),
    competitorAnalysis: yup.string().nullable().required('Required'),
    goToMarketStrategy: yup.string().nullable().required('Required'),
    exitStrategy: yup.string().nullable().required('Required'),
    businessPlanDD: yup.mixed().required(),
  }),
  yup.object().shape({
    stage: yup.string().required('Required'),
    logo: yup.mixed().required(),
    investmentRaisedAmount: yup.string().nullable().required('Required'),
    investmentNeededDD: yup.string().nullable().required('Required'),
    lastRoundDate: yup.string().nullable().required('Required'),
    lastRoundInvestment: yup.string().nullable().required('Required'),
    longOfSustainBusiness: yup.string().nullable().required('Required'),
    postMoneyValuation: yup.string().nullable().required('Required'),
  }),
];

export const changePasswordSchema = yup.object().shape({
  newPassword1: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
    ),

  newPassword2: yup
    .string()
    .required('Password Confirm is required')
    .oneOf([yup.ref('newPassword1'), null], 'Passwords must match'),
});
