import { Button, Grid, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { Fragment } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import get from 'lodash/get';
import { signupSchema } from '../../yupSchemas';
import { withRouter } from 'react-router';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//import tealCheck from 'static/img/tealCheck.svg';

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '73vh',
    width: '100%',
  },
  progresText: {
    backgroundColor: 'white',
    bottom: 0,
    justifyContent: 'flex-end',

    position: 'fixed',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },

  root: {
    padding: 0,
  },
});
class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitting: false,
      serverSuccess: false,
      page: 0,
      values: props.initialValues,
      serverMessage: {
        detail: '',
        message: '',
        type: false,
      },
    };
  }

  /*   handleManuelCheck = values => {
    if (values.type !== 'Team' && values.communityRelated === '') {
      this.setState({ error: true });
    } else if (values.name === '') {
      this.setState({ error: true });
    } else if (values.type !== 'Community' && !values.help && !values.promote && !values.support) {
      this.setState({ error: true });
    } else if (values.newAddress === '') {
      this.setState({ error: true });
    } else if (values.privacy === '') {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  }; */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };
  setPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  handleSubmit = async (variablesAll) => {
    const { userAgreement, passwordConfirm, reCaptcha, ...variables } = variablesAll;
    this.setState({ submitting: true });
    variables.email = variables.email.toLowerCase();
    this.props
      .registerInvestor({ variables })
      .then((data) => {
        if (data.data.registerInvestor) {
          const token = data.data.registerInvestor.token;
          let params = new URLSearchParams(this.props.history.location.search);

          this.props.setAuthToken(token);

          if (data.data.registerInvestor.user.role === 'StartupContact') {
            if (data.data.registerInvestor.user.startup.applicationStage === 'FirstApplication') {
              this.props.history.push(params.get('next') ? params.get('next') : `/application-form`);
            } else {
              this.props.history.push(params.get('next') ? params.get('next') : `/my-startup`);
            }
          } else if (
            data.data.registerInvestor.user.role === 'SuperAdmin' ||
            data.data.registerInvestor.user.role === 'Admin'
          ) {
            this.props.history.push(params.get('next') ? params.get('next') : `/admin/dashboard`);
          } else {
            if (data.data.registerInvestor.user.contractSigned) {
              this.props.history.push(params.get('next') ? params.get('next') : `/landing`);
            } else {
              this.props.history.push(params.get('next') ? params.get('next') : `/first-login`);
            }
          }
        } else {
          this.setState({
            serverMessage: {
              detail: get(data, 'errors[0].functionError.detail', ' '),
              message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
              type: false,
            },
          });
          this.setState({ serverSuccess: true });
        }
      })
      .then(() => this.setState({ submitting: false }))
      .catch((error) => {
        this.setState({
          serverMessage: {
            message: 'Unexpected Error',
            type: false,
          },
        });
        this.setState({ serverSuccess: true });
      });
  };
  /* 
  disableCreateButton = values => {
    if (values.type !== 'Team' && values.communityRelated === '') {
      return true;
    } else if (values.name === '') {
      return true;
    } else if (values.type !== 'Community' && !values.help && !values.support) {
      return true;
    } else if (values.newAddress === '') {
      return true;
    } else if (values.privacy === '') {
      return true;
    } else {
      return false;
    }
  };
 */

  render() {
    const { children, classes } = this.props;
    const { values, page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const pageCount = React.Children.toArray(children).length - 1; // toArray removes null values count doesn't
    const isLastPage = page === pageCount;
    return (
      <Fragment>
        <Formik
          validationSchema={signupSchema[page]}
          initialValues={values}
          enableReinitialize={true}
          validate={this.validate}
          validateOnMount={true}
          onSubmit={this.handleSubmit}
          render={({ values, handleSubmit, isSubmitting, handleReset, isValid, validateForm }) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
              {activePage}
              <Grid container xs={12} spacing={3}>
                {isLastPage ? (
                  <Grid item container xs={12} style={{ marginTop: 30 }}>
                    <Button
                      onClick={() =>
                        this.setState({ page: this.state.page - 1 }, () => {
                          validateForm().then((r) => {});
                        })
                      }
                      variant="outlinedSecondary"
                      style={{ fontWeight: 'normal', marginRight: '25px' }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant="containedPrimary"
                      style={{ fontWeight: 'normal' }}
                      disabled={!isValid}
                      type="submit"
                    >
                      {' '}
                      SEND{' '}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item container xs={12} justify="center" style={{ marginTop: 30 }}>
                    <Button
                      variant="containedPrimary"
                      disabled={!isValid}
                      style={{ fontWeight: 'normal' }}
                      onClick={() =>
                        this.setState({ page: this.state.page + 1 }, () => {
                          validateForm().then((r) => {});
                        })
                      }
                    >
                      {' '}
                      CONTINUE{' '}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        />
        <Snackbar
          data={this.state.serverMessage}
          onClose={() => this.setState({ serverSuccess: false })}
          open={this.state.serverSuccess}
        >
          <Alert severity="error">That email is already taken</Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Wizard));
