import { Form, Formik, useField } from 'formik';

import AgreementPdf from '../../static/AE Sözleşme(MAY060921).pdf';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Container } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { MY_PROFILE } from '../../queries';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import createBrowserHistory from 'history/createBrowserHistory';
import { firstLoginSchema } from '../../yupSchemas';
import get from 'lodash/get';
import logo from '../../static/img/new-ae-logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 150,
    height: 50,
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
  },
  mobileField: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px!important',
    },
  },
  toolbar: {
    padding: '0px 100px',
  },
  inputField: {
    marginTop: 5,
    width: '100%',
    background: '#ffffff',
  },
  text: {
    marginBottom: 30,
  },
  applicationCard: {
    background: '#ffffff',
    marginBottom: 30,
    borderRadius: 7,
    border: 'solid 1px #dddddd',
  },
}));
function TCNOKontrol(TCNO) {
  var tek = 0,
    cift = 0,
    sonuc = 0,
    TCToplam = 0,
    i = 0;

  if (TCNO.length != 11) return 'Not a valid T.C.';
  if (isNaN(TCNO)) return 'Not a valid T.C.';
  if (TCNO[0] == 0) return 'Not a valid T.C.';

  tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
  cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

  tek = tek * 7;
  sonuc = Math.abs(tek - cift);
  if (sonuc % 10 != TCNO[9]) return 'Not a valid T.C.';

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(TCNO[i]);
  }

  if (TCToplam % 10 != TCNO[10]) return 'Not a valid T.C.';

  return false;
}
function FirstLogin(props) {
  const classes = useStyles();
  const history = createBrowserHistory({ forceRefresh: true });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [user, setUser] = React.useState({});
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;
  const [serverMessage, setServerMessage] = React.useState({
    detail: '',
    message: '',
    type: false,
  });
  const handleBack = () => {
    localStorage.clear();
    history.push('/login');
  };
  const { loading, data } = useQuery(MY_PROFILE, {
    fetchPolicy: 'cache-first',
    variables: {},
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setUser(data.myProfile);
      }
    }
  }, [data, loading]);
  const FormikTextField = ({ label, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          {disabled ? (
            <TextField
              variant="outlined"
              className={classes.inputField}
              disabled
              {...field}
              {...props}
              error={meta.touched && meta.error}
              placeholder={props.placeholder}
              maxlength={props.maxlength}
            />
          ) : (
            <TextField
              variant="outlined"
              className={classes.inputField}
              {...field}
              {...props}
              error={meta.touched && meta.error}
              placeholder={props.placeholder}
              maxlength={props.maxlength}
            />
          )}

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  };
  const FormikCheckBox = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <Grid xs={12} container>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                {...field}
                {...props}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                color="primary"
              />
            }
          />
          {label}
        </Grid>
        {meta.touched && meta.error ? (
          <Grid xs={12}>
            <Typography className={classes.label} style={{ color: '#FF0000' }} variant="subtitle2">
              {meta.error}
            </Typography>
          </Grid>
        ) : null}
      </>
    );
  };
  const handleSubmit = async (variablesAll) => {
    setIsSubmitting(true);
    const { nameSurname, userAgreement, ...variables } = variablesAll;
    variables.date = variables.date.today;
    props
      .investorSignContract({ variables })
      .then((data) => {
        if (data.data.investorSignContract) {
          props.history.push('/landing');
        } else {
          setServerMessage({
            detail: get(data, 'errors[0].functionError.detail', ' '),
            message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
            type: false,
          });
          setServerSuccess(true);
        }
      })
      .then(() => {
        setIsSubmitting(false);
      })
      .catch((error) => {
        setServerMessage({
          message: 'Unexpected Error',
          type: false,
        });
        setServerSuccess(true);
      });
  };
  return (
    <div>
      <Hidden mdDown>
        <AppBar position="static" style={{ background: '#ffffff' }} elevation={1}>
          <Toolbar variant="dense" disableGutters className={classes.toolbar}>
            <img src={logo} className={classes.logo} alt="angel-effect"></img>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify="center" style={{ background: '#ffffff', paddingBottom: 30 }}>
          <img src={logo} alt="angel-effect" className={classes.logo} style={{ marginRight: '10px' }}></img>
        </Grid>
      </Hidden>
      <Container maxWidth="xl">
        <Grid container justify="center">
          <Formik
            initialValues={{
              nameSurname: '',
              date: { today },
              address: '',
              tcNumber: '',
              userAgreement: false,
            }}
            validationSchema={firstLoginSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Grid xs={12} lg={10} xl={9}>
                <Form>
                  <Grid container xs={12}>
                    <Typography variant="h3" align="center" style={{ marginTop: 50 }}>
                      Accept Non-Disclosure Agreement
                    </Typography>
                  </Grid>
                  <Grid container spacing="6" style={{ marginTop: 40, marginBottom: 0 }}>
                    <Grid item xs={12} md={3} className={classes.mobileField}>
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        Name Surname
                      </Typography>
                      <FormikTextField
                        name="nameSurname"
                        disabled="disabled"
                        value={user.firstName + ' ' + user.lastName}
                        placeholder="Name Surname"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.mobileField}>
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        Address
                      </Typography>
                      <FormikTextField name="address" placeholder="Address" />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.mobileField}>
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        T.C.
                      </Typography>
                      <FormikTextField name="tcNumber" placeholder="T.C." validate={TCNOKontrol} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md="auto"
                      direction="column"
                      justify="center"
                      style={{ display: 'flex' }}
                      className={classes.mobileField}
                    >
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        Date
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {today}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className={classes.applicationCard} container>
                    <Grid item container direction="column" xs={12} style={{ padding: '20px 25px', height: '100vh' }}>
                      <iframe src={`${AgreementPdf}#toolbar=0`} width="100%" height="100%"></iframe>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} style={{ marginBottom: 30 }}>
                    <FormikCheckBox
                      name="userAgreement"
                      type="Checkbox"
                      label={
                        <Typography
                          className={classes.mobileFont}
                          variant="body2"
                          style={{ alignItems: 'center', display: 'flex' }}
                        >
                          I HAVE READ AND AGREED TO THE AE BASIC MEMBER NETWORK PARTICIPATION AGREEMENT
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid container xs={12} style={{ marginBottom: 30, flexWrap: 'nowrap' }}>
                    <Button
                      onClick={handleBack}
                      variant="outlinedSecondary"
                      style={{ fontWeight: 'normal', marginRight: '25px' }}
                    >
                      BACK
                    </Button>
                    <Button
                      variant="containedPrimary"
                      style={{ fontWeight: 'normal' }}
                      type="submit"
                      disabled={!props.isValid}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Form>
              </Grid>
            )}
          </Formik>
        </Grid>
      </Container>
    </div>
  );
}

export default FirstLogin;
